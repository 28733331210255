import React from 'react'
import { Link } from 'gatsby'
import _variables from '../../scss/common/_variables.scss'
import PropTypes from 'prop-types'

const SolidButton = ({ label, url, btnwidth, prev, order }) => (
  <Link
    to={url}
    className="solidButton"
    style={{
      padding: '10px 20px',
      fontWeight: '600',
      textTransform: 'uppercase',
      width: btnwidth ? btnwidth : 'auto',
    }}
    state={{
      order_scroll: order || 1,
      previous_order: prev || 0,
    }}
  >
    {label}
  </Link>
)

export default SolidButton

SolidButton.propTypes = {
  label: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  btnwidth: PropTypes.string,
  prev: PropTypes.number,
  order: PropTypes.number,
}
