import React, { Component } from 'react'
import SolidButton from '../buttons/SolidButton'
import _variables from '../../scss/common/_variables.scss'
import { ModalContext } from '../WrapPageElement'

class DownloadForm extends Component {
  render() {
    let formClass = ''
    let modalClass = ''
    if (!this.props.displayDownloadForm) {
      formClass = ' hidden'
      modalClass = ' hidden'
    } else {
      formClass = ''
      modalClass = ' zoomIn'
    }
    return (
      <React.Fragment>
        <div>
          <div className={`overlay ${formClass}`} />
          <div className={`downloadForm ${modalClass}`}>
            <i
              className="fas fa-times"
              style={{
                position: 'absolute',
                right: 10,
                top: 10,
                fontSize: 25,
                cursor: 'pointer',
              }}
              onClick={this.props.onCloseForm}
            />
            <form>
              <h1>Download the report</h1>
              <p>
                Enter your name and email below to download the full report.
              </p>

              <div className="formElem">
                <p style={{ position: 'relative' }}>
                  <input
                    className="form-control"
                    type="name"
                    name="name"
                    id="name"
                    placeholder="Your name"
                  />
                  <span>*</span>
                </p>
                <p style={{ position: 'relative' }}>
                  <input
                    className="form-control"
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Your email"
                  />
                  <span>*</span>
                </p>
                <p>
                  <label htmlFor="terms">
                    <input type="checkbox" name="terms" id="terms" /> I agree to
                    the terms &amp; conditions *
                  </label>
                </p>
                <p>
                  <label htmlFor="mailingList">
                    <input
                      type="checkbox"
                      name="mailingList"
                      id="mailingList"
                    />{' '}
                    I’d like to receive occasional updates and news from MMC
                    Ventures
                  </label>
                </p>
              </div>
              <SolidButton
                label="Download the report"
                url=""
                style={{
                  background: _variables.darkblue,
                }}
              />
            </form>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default DownloadForm
